import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { CanvasJSChart } from "canvasjs-react-charts";
import { useCookies } from "react-cookie";
import ListingTable from "../../../listing/listing";
import { inputExternalPlugin } from "../../../helper/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdminDashboardData,
  clearAdminDashboardUserListData,
  getDataFoAdminDashboardCard,
  getDataFoAdminDashboardChart,
  getDataFoAdminDashboardGridList,
  setMinMaxTime,
} from "./DashboardReducer";
import store from "../../../store";
import moment from "moment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FolderIcon from "@mui/icons-material/Folder";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StreetviewIcon from "@mui/icons-material/Streetview";
import CloseIcon from "@mui/icons-material/Close";
import DiscoveryCallwithLead from "./DiscoveryCallwithLead";

// import TextField from '@mui/material/TextField';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccessREP from "./AccessREP/AccessREP";
import FollowUpCalladded from "./FollowUpCalladded";
import AccessLaed from "./AccessLaed";
import ConversionCreate from "./ConversionCreate";
import OpportunityCreated from "./OpportunityCreated";

import {
  getAdminDashbordListData,
  getOpportunuitylistData,
  getAppointmentBookingListData,
  getAccessedRepData,
  cleardashbordListdata,
  clearGridListName,
  getAdminChartData,
  clearGridList,
} from "./DashbordListReducer";
import LeadAppointment from "./LeadAppointment";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import CompletedTrainingList from "./CompletedTrainingList";
import EmailSentList from "./EmailSentList";
import InformationLinkSentList from "./InformationLinkSentList";
import { resolvefunction } from "../../../helper/helperFunctions";
import { folderViewVisitLog } from "../userManagement/folderView/folderViewReducer";
import DNotes from "./DNotes";
import DiscoveryCallwithREPList from "./DiscoveryCallwithREPList";
import AllActivitiesList from "./AllActivitiesList";
import {
  clearTableOtherData,
  setEnableRowLoader,
} from "../../../listing/listReducer";
import { getfieldeditdata } from "../FormManagement/FormManagementReducer";
import BtnsComponent from "./BtnsComponent";
import CommonBtn from "./CommonBtn";
import BtnDower from "./BtnDower";
import { fetchContract, fetchContractTemplate, rolesForUpgrade, usermanagestatusUpdate } from "../userManagement/userList/userListReducer";
import UpgradeRoleModal from "../userManagement/userList/upgradeRoleModal";
import ManageStageModal from "../userManagement/userList/manageStageModal";
import ContactDrawer from "../userManagement/userList/sendcontractDrawer";
import ContractViewModal from "../userManagement/userList/contractViewModal";
import ManageHmSettingModal from "../userManagement/userList/manageHmSettingModal";
import ConfigureTagsModal from "../userManagement/userList/configureTagsModal";
import ConfigureRoundRobinModal from "../userManagement/userList/configureRoundRobinModal";
import FollowUpcall from "../LeadManagement/FollowUpcall";
import TrainingStatusModal from "../userManagement/userList/trainingStatusModal";
import ConfigureRoleModal from "../userManagement/userList/configureRoleModal";
import EmailSendDrower from "../LeadManagement/EmailSendDrower";
import MultiStatusChangeModal from "../../../commoncomponents/modal/multipleStatusChnageModal";
import LastLoginModal from "../userManagement/userList/lastloginmodal";
import PopupModal from "../../../commoncomponents/modal/popupmodal";
import ShowStageListModal from "../LeadManagement/ShowStageListModal";
import ShowTagListModal from "../LeadManagement/ShowTagListModal";
import { clearinformationlistdata } from "../InformationlinkModule/InformationReducer";
import InformationSentOption from "../LeadManagement/InformationSentOption";
// import CompletedTrainingList from "./CompletedTrainingList";

export default function Dashboard() {
  const [roundRobinModal, setRoundRobinModal] = useState(false);
  const [upgradeRoleModal, setUpgradeRoleModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openBtnDrower, setOpenBtnDrower] = useState(false);
  const [searBtnClick, setsearchBtnClick] = useState(0);
  const [searchGridData, setSearchGridData] = useState([]);
  const [filteredGridData, setfilteredGridData] = useState([]);
  const [stageModal, setStageModal] = useState(false);
  const [contractDrawer, setContractDrawer] = useState(false);
  const [contractPreviewModal, setContractPreviewModal] = useState(false);
  const [hmSettingModal, setHmSettingModal] = useState(false);
  const scrollToGridData = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  console.log("->>>>>>>>>>>>>>>>>>>", userInfo);
  const [isScriptTag, setIsScriptTag] = useState(false);
  const [statusAction, setStatusAction] = useState(null);
  const [tagManageModal, setTagManageModal] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [trainingDataModal, setTrainingDataModal] = useState(false);
  const [endpointResStatus, setEndpointResStatus] = useState(null); //--- resMessage  ----//
  const [snackFire, setSnackFire] = useState(false); //--- Open SnackBar ----//
  const [resMessage, setResMessage] = useState(null); //--- resMessage  ----//
  const [configureRoleModal, setConfigureRoleModal] = useState(false);
  const [openDrowerForEmail, setOpenDrowerForEmail] = React.useState(false);
  const [multiIdData, setMultiIdData] = useState([]);
  const [multiMail, setMultiMail] = useState(false);
  const [multiStatusModal, setMultiStatusModal] = useState(false); //--- Toogle ----//
  const [confrimationMsg, setConfrimationMsg] = useState(null); //--- ConfrimationMsg  ----//
  const [loginPopupData, setloginPopupData] = useState([]); //--- Toogle ----//
  const [loginPopupToogle, setloginPopupToogle] = useState(false); //--- Toogle ----//
  const [popupData, setPopupData] = useState([]); //--- popupData ----//
  const [popupToogle, setPopupToogle] = useState(false); //--- Toogle ----//
  const [btnType, setBtnType] = useState(""); //--- ButtonType Set  ----//
  const [showstageList, setshowstageList] = useState(false);
  const [showstageListAction, setshowstageListAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//
  const [showtagListModal, setshowtagListModal] = useState(false);
  const [statuBtnClick, setStatusBtnClick] = useState(false)
  const [addedForId, setAddedForId] = useState({});
  const [gridsingelUser,setGridSingelUser]=useState({})
  const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);
  /************************ For information link Send Section start ************************************ */
  const [openDrowerForInfoLink, setOpenDrowerForInfoLink] = useState(false);
  const informationsendClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.informationLink);
  let infoLinksData = useSelector((state) => state.informationReducer?.informationlistdata && state.informationReducer?.informationlistdata.length > 0 ? state.informationReducer.informationlistdata : []);
  let loaderForInformationSend = useSelector((state) => state.informationReducer?.loading);

  const closeDrowerForInfoLink = () => {
    setOpenDrowerForInfoLink(false);
    dispatch(clearinformationlistdata());
  };
  /************************ For information link Send Section end ************************************** */



  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/dashboard"]
      ? state.ResolveReducer.resolved["/dashboard"]
      : false
  );

  const [value, setValue] = useState([null, null]);
  const [scroll, setScroll] = useState(false);
  const [selectedBlock, setselectedBlock] = useState("Users Created");

  let userID = useSelector((state) =>
    state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
  );
  let lastloginTime = useSelector((state) => state.loginSlice.last_login_time);
  console.log("-------------------lastloginTime", lastloginTime);
  const FetchHMSettingModalData = useSelector(state => (state.userManagment?.HMSettingModalData) ? state.userManagment?.HMSettingModalData : null)
  let bttnClicked = useSelector((state) => state.tableSlice.customButtonClicked?.dashboardTable?.summarize_icon || state.tableSlice.customButtonClicked?.dashboardTable?.assessment_icon === true);

  let mindate = useSelector((state) => state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {});
  let maxDate = useSelector((state) => state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {});

  let adminCardData = useSelector((state) => state.DashboardReducer?.adminCardData ? state.DashboardReducer.adminCardData : {});
  let adminChartData = useSelector((state) => state.DashboardReducer?.adminChartData ? state.DashboardReducer.adminChartData : {});

  let adminGridData = useSelector((state) => state.tableSlice?.otherResData?.dashboardTable?.grid_data ? state.tableSlice?.otherResData?.dashboardTable?.grid_data : state.DashboardReducer?.adminGridData ? state.DashboardReducer.adminGridData : {});

  let adminListData = useSelector((state) => state.DashboardReducer?.adminListData ? state.DashboardReducer.adminListData : null);

  const [openMoadl, setOpenMoadl] = React.useState(false);
  const [openMoadlforNotes, setOpenMoadlforNotes] = React.useState(false);
  const loaderForAdminData = useSelector((state) => state.DashboardReducer?.loaderForAdminData ? state.DashboardReducer.loaderForAdminData : false);
  const usePaylod =useSelector(state=>state.DashboardListReducer?.usePaylod?state.DashboardListReducer.usePaylod:[])
  // <---------------------------------listData----------------------->
  const listType = useSelector((state) =>
    state.DashboardListReducer?.listType
      ? state.DashboardListReducer.listType
      : null
  );
  const listData = useSelector((state) =>
    state.DashboardListReducer?.ListData
      ? state.DashboardListReducer.ListData
      : []
  );
  let gridData = useSelector((state) =>
    state.DashboardListReducer?.gridData
      ? state.DashboardListReducer.gridData
      : null
  );
  const gridListName = useSelector((state) =>
    state.DashboardListReducer?.gridListName
      ? state.DashboardListReducer.gridListName
      : null
  );
  const loader = useSelector((state) =>
    state.DashboardListReducer?.loader
      ? state.DashboardListReducer.loader
      : false
  );
  const regulerChartData = useSelector((state) =>
    state.DashboardListReducer?.chartData
      ? state.DashboardListReducer.chartData
      : []
  );
  const folderViewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.dashboardTable &&
      state.tableSlice?.customButtonClicked?.dashboardTable?.folder_icon
      ? state.tableSlice?.customButtonClicked?.dashboardTable?.folder_icon
      : false
  );
  const leadViewBtn = useSelector((state) =>state.tableSlice?.customButtonClicked?.dashboardTable &&state.tableSlice?.customButtonClicked?.dashboardTable?.streetview_icon? state.tableSlice?.customButtonClicked?.dashboardTable?.streetview_icon: false);
  const dataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.dashboardTable ? state.tableSlice?.tempRowData?.dashboardTable : {});
  const OpdataRef = useSelector(state => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.OptableDataDashboard ? state.tableSlice.tempRowData.OptableDataDashboard : {})

  // const searchFormDashbord = useSelector(state=>state.formSlice?.formSubmissionState?.searchFormDashbord?state.formSlice.formSubmissionState.searchFormDashbord:0)
  const searchFormData = useSelector((state) => state.formSlice?.formData?.searchFormDashbord ? state.formSlice.formData.searchFormDashbord : {});

  // more custom button click
  const moreBtnClickUser = useSelector((state) => state.tableSlice?.customButtonClicked?.dashboardTable && state.tableSlice?.customButtonClicked?.dashboardTable?.MoreHorizIcon_icon ? state.tableSlice?.customButtonClicked?.dashboardTable?.MoreHorizIcon_icon : false);
  const moreButnClickOp = useSelector((state) => state.tableSlice?.customButtonClicked?.OptableDataDashboard?.MoreHorizIcon_icon ? state.tableSlice?.customButtonClicked?.OptableDataDashboard?.MoreHorizIcon_icon : false)
  const userRolesNewData = useSelector((state) => state.userManagment?.userRolesNew ? state.userManagment.userRolesNew : []);
  const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment.FetchStages : [])    // <<<<<<<<<<--------------data for fetch stage Modal
  const contractData = useSelector(state => (state.userManagment?.fetchContractData) ? state.userManagment.fetchContractData : [])   //<<<<<<<<----------------data for fetch Contract Modal
  const contractTemplateData = useSelector(state => (state.userManagment?.contractTemplate) ? state.userManagment.contractTemplate : {}); //<<<<<<---------------data for fetch contractprivew modal
  const TagManageModalData = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : []);
  const roundRobinData = useSelector((state) =>
    state.userManagment?.roundRobinData
      ? state.userManagment.roundRobinData
      : null
  );

  const followUpCallList = useSelector((state) =>
    state.LeadReducer?.followUpCallData &&
      state.LeadReducer?.followUpCallData.length > 0
      ? state.LeadReducer.followUpCallData
      : []
  );

  const loaderForFollowUp = useSelector(
    (state) => state.LeadReducer?.loaderForFollowUp
  );

  const userTrainingData = useSelector((state) =>
    state.userManagment?.userTrainingData
      ? state.userManagment.userTrainingData
      : null
  );

  const trainingData = useSelector((state) =>
    state.userManagment?.trainingData ? state.userManagment.trainingData : []
  );




  // -------------------------------------RoleData ----------------------------------
  const userRoles = useSelector((state) =>state.userManagment?.userRoles ? state.userManagment.userRoles : []);

  const emailtemplateData = useSelector((state) =>
    state.EmailTemplateReducer?.emailtemplateListData
      ? state.EmailTemplateReducer.emailtemplateListData
      : []
  );

  const loaderForsendmail = useSelector(
    (state) => state.LeadReducer?.loaderForsendmail
  );

  const submistionstate = useSelector(
    (state) => state.LeadReducer?.submistionstate
  );

  const sendContractBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.userslist1?.send_contract
      ? state.tableSlice.customButtonClicked.userslist1.send_contract
      : false
  );
  const fetchData = useSelector((state) =>
    state.fetchUserDataReducer?.fetchUserDataReducer
      ? state.fetchUserDataReducer.fetchUserDataReducer
      : null
  );

  const chnageStatus = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.userslist1 &&
      state.tableSlice?.customButtonClicked?.userslist1?.change_status1
      ? state.tableSlice?.customButtonClicked?.userslist1?.change_status1
      : false
  );

  const fetchStagelistData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer.ShowStageListData : []) // ------- FetchedStagelistData
  const showStagebtnData = useSelector(state => (state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0) ? state.tableSlice.tempRowData.leaddata : null); //-----showStatgeTable Data ------


  let taglifecycledatas = useSelector((state) => state.LeadReducer?.taglifecycledata && state.LeadReducer?.taglifecycledata.length > 0 ? state.LeadReducer?.taglifecycledata : []);





  // -------------------settings for sigale data to fetch -------------------- 
  useEffect(() => {
    console.log(OpdataRef, "datareff111");
    if (Object.keys(dataRef).length > 0) {
      setRowData(dataRef);
    } else if (Object.keys(OpdataRef).length > 0) {
      setRowData(OpdataRef)
    }else if(Object.keys(gridsingelUser).length > 0){
      setRowData(gridsingelUser)
    }
  }, [JSON.stringify(dataRef), JSON.stringify(OpdataRef),gridsingelUser]);







// btndrower function
  const handleCloseDrawer = () => {
    if (openBtnDrower === true) setOpenBtnDrower(false);
  };


  //   useEffect(()=>{
  // console.log("userRolesNewData",userRolesNewData);
  //   },[userRolesNewData])



  //  if sendContractBtn triggred ---



  useEffect(() => {
    setContractPreviewModal(false);

    if (sendContractBtn) {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataRef._id,
          load: true,
        })
      ); //---- row loadder ----
      setDataCopy(dataRef);
      setContractPreviewModal(false);

      const data = {
        condition: {},
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          status: 1,
          rep_only: 1,
        },
        project: { name: 1 },
        token: "",
        data: { user_id: dataRef._id },
      };

      dispatch(fetchContract(data));
    }
  }, [sendContractBtn]);








  useEffect(() => {
    setSnackFire(false);
    if (emailtemplateData?.length > 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);
      setOpenDrowerForEmail(true);
    } else if (emailtemplateData?.length == 0) {
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "userslist1",
            rowId: dataCopy._id,
            load: false,
          })
        ); //---- row loadder false----
      }, 1000);

      setSnackFire(true);
      setResMessage("No Email Template Found!");
      setEndpointResStatus("failed");
    }
  }, [emailtemplateData]);

  const closeDrower = () => {
    setOpenDrowerForEmail(false);
  };

  useEffect(() => {
    setSnackFire(false);
    // console.warn(" <<<< user role lenght >>>>", userRoles.length)
    if (userRoles.length > 0 && userRoles !== null) {
      setSnackFire(false);
      setConfigureRoleModal(true);
    } else if (userRoles == null) {
      setSnackFire(true);
      setResMessage("Roles not found!");
      setEndpointResStatus("failed");
    }
  }, [userRoles]);

  useEffect(() => {
    if (trainingData.length > 0) {
      setTrainingDataModal(true);
    } else {
      setSnackFire(true);
      setResMessage("No Training Data Found!");
      setEndpointResStatus("failed");
    }
  }, [trainingData]);
  //------------------

  // --- On Successful endpoint call followup listing open ---
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        setEnableRowLoader({
          tableId: "userslist1",
          rowId: dataCopy._id,
          load: false,
        })
      ); //---- row loadder false----
    }, 1000);

    if (followUpCallList.length > 0) {
      setFollowUpModal(true);
    }
  }, [JSON.stringify(followUpCallList)]);



  // ------- if roundRobinData found after endpoint call
  useEffect(() => {
    if (roundRobinData !== null) {
      setRoundRobinModal(true);
    }
  }, [roundRobinData]);
  // ---------------

  useEffect(() => {
    setStatusAction(null)
  }, [])

  useEffect(() => {
    if (FetchHMSettingModalData !== null) {
      setHmSettingModal(true)
    }

  }, [FetchHMSettingModalData])



  useEffect(() => {
    if (moreBtnClickUser === true || moreButnClickOp === true) setOpenBtnDrower(true);
  }, [moreBtnClickUser, moreButnClickOp]);

  useEffect(() => {
    if (userRolesNewData.length > 0) {
      setUpgradeRoleModal(true);
    }
  }, [userRolesNewData]);
















  useEffect(() => {
    tableDataDashboard.current.searchData.formData.fields.forEach((data) => { data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined; });
  }, [JSON.stringify(searchFormData)]);







  useEffect(() => {
    if (searBtnClick === 1) {
      setfilteredGridData([]);
    }
  }, [searBtnClick]);

  let reqbody = {
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      field: "createdon_datetime",
      type: "desc",
    },
    searchcondition: {
      // _id: userID,
      createdon_datetime: {
        $gte: mindate,
        $lte: maxDate,
      },
    },
    project: {},
    token: "",
    count: false,
  };








  useEffect(() => {
    if (fetchData !== null && fetchData !== undefined) {
      // dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
      setPopupData(fetchData);
      setPopupToogle(true);
    }
  }, [fetchData]);









  useEffect(() => {
    if (!isScriptTag) {
      inputExternalPlugin(
        "dashboard",
        "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js"
      );
      setIsScriptTag(true);
    }
  }, [isScriptTag]);




  useEffect(() => {
    if (statuBtnClick === true) {
      setBtnType("singleStatusBtn");
      setConfrimationMsg("Are you sure you that want to change the status?");
      setMultiStatusModal(true);
    }
  }, [statuBtnClick])










  {
    /****************************************list section using react reuse******************************************** */
  }


  var modifyTableHeaders = [
    { val: "fullname", name: "Name" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Phone" },
    { val: "roles_names", name: "Role" },
    {
      val: "createdon_datetime",
      name: "Added On",
      type: "datetime",
      format: "DD/MM/YYYY",
    },
  ];





  let tableDataDashboard = useRef({
    tableId: "dashboardTable",
    tableTitle: "User List View",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/admin-dashboard-list-grid",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/admin-dashboard-list-grid",
    },

    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        // _id: userID,
        createdon_datetime: {
          // $gte: mindate,
          $lte: maxDate,
        },
      },
      source: "users",
      type: "user_created",
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: [
      "fullname",
      "email",
      "createdon_datetime",
      "updatedon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-delete",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      //  ########################### Notes ########################################
      {
        id: "notesBadge", // for unique id of button//
        label: "Notes", //on hover show//
        type: "note", //****button category */
        icon_type: "summarize_icon", //for the button icon from mui icons  https://mui.com/material-ui/material-icons///
        name: "note_inbuilt", //as your wish///
        className: "forminline", //for modal top level tag class name//
        count_key: "notescount", //count of notes, view in badge, value should be in row data object//
        notes_for_key: "fullname", //modal title notes for dynamic data, value should be in row data object
        noteaddbaseurl: process.env.REACT_APP_API_URL, //for end point base path//
        noteaddendpoint: "note/add-note", //final path of end point//
        noteaddbody: {
          //default body to add notes//
          data: {
            notes_by: userInfo?.userinfo?._id,
            collection_name: "users",
          },
        },
        body: {
          source: "",
          condition: { limit: 5, skip: 0 },
          sort: { type: "desc", field: "assigned_datetime" },
          searchcondition: {},
        }, //for initial listing end point call body//
        tableData: {
          //config for notes table in side modal like normal table config//
          tableId: "notesTable",
          tableTitle: " Notes Table",
          no_data_html: "<p>No Data Found </p>",
          payload_key: "notes_for",
          showReload: true,
          showFilter: true,
          api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "note/note-list-count",
            deleteSingleUserEndpoint: "note/delete-notes",
            tableCountEndpoint: "note/note-list-count",
          },
          reqBody: {
            condition: {
              limit: 5,
              skip: 0,
            },
            searchcondition: {},
            sort: {
              type: "desc",
              field: "createdon_datetime",
            },
          },
          deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
          rowsPerPageOptions: [5, 10, 20, 30],
          sortFields: ["notesby_names", "createdon_datetime", "notes"],
          paginationType: "stackPagination", ////////////////////// By Default Rows per page option will be available////////////,
          customBtnHead: [],
          buttons: [
            // {
            //   id: "deletetable01",
            //   type: "delete",
            //   label: "Delete",
            //   name: "delete",
            //   condField: "notes_by",
            //   condVal: loggedUserId,
            // }

            {
              id: "delete",
              label: "Multi Delete",
              type: "api",
              category: "modal",
              condField: "notes_by",
              condVal: userInfo?.userinfo._id,
              icon_type: "delete",
              className: "delete_data",
              modalClassName: "delete_modal",
              modalHeader: "<p> Alert !!</p>",
              modalTitle:
                "<p>Are you sure you want to delete this record? This can't be undone.</p>",
              btn_label: ["Yes", "No"],
              api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "note/delete-notes",
                body: {
                  source: "users",
                  secret: "AZ|lepL`",
                  token:
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                },
              },
            },
          ],
          deleteModal: {
            modalClassName: "Delete Note",
          },

          searchBarData: {
            formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
            formInlineCLass: "userForm", // Can be undefined default will be formInline
            formButtonClass: "submitbtnsection",
            submitBtnEndIcon: "search",
            resetBtnEndIcon: "replay",
            endPointBodyWrapper: "searchcondition",
            heading: "Search Notes",
            class: "draWerp",

            api: {
              url: process.env.REACT_APP_API_URL,
              endPoint: "note/note-list-count",
              tableCountEndpoint: "note/note-list-count",
              reqBody: {
                source: "",
                condition: {
                  limit: 5,
                  skip: 0,
                },
                sort: {
                  type: "desc",
                  field: "_id",
                },
                searchcondition: {
                  notes_for: ""?._id,
                },
              },
            },
            searchSettings: {
              datesearch: [
                {
                  id: 0,
                  // heading: "Search By Joining Date",
                  startdatelabel: "Start Date",
                  enddatelabel: "End Date",
                  fullDay: true,
                  className: "inputblock4line",
                  field: "createdon_datetime",
                },
              ],

              textsearch: [
                // {
                //     id: 1,
                //     //   heading: "Search By Note",
                //     label: "Search By Note",
                //     field: "notes",
                //     className: "inputblock4line",
                //     value: "",
                // },
                // {
                //     id: 2,
                //     // heading: "Search Added By",
                //     label: "Search Added By",
                //     field: "added_name",
                //     className: "inputblock4line",
                //     value: "",
                // }
              ],
              autoCompleteSearch: [
                {
                  id: 6,
                  label: "Search Notes By ",
                  field: "notes_for",
                  values: "",
                  multiple: false,
                  preload: true,
                  className: "inputblock4line",
                  serverSearchData: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "",
                    reqBody: {
                      condition: {
                        limit: 10,
                        skip: 0,
                        notes_for: ""?._id,
                      },
                      sort: {
                        type: "desc",
                        field: "_id",
                      },
                      searchcondition: { notes_for: ""?._id },
                    },
                  },
                },
              ],

              selectsearch: [],
            },
          },
        },
        headers: [
          { val: "notes", name: "Note" },
          { val: "added_name", name: "Added By" },

          {
            val: "createdon_datetime",
            name: "Added On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm:ss",
          },
        ],
      },
      {
        id: "folder_icon",
        label: "Folder View",
        type: "other",
        name: "folder_icon",
        title: "Folder View",
      },
      {
        id: "assessment_icon",
        label: "Training Report",
        type: "other",
        name: "assessment_icon",
        title: "Training Report",
      },
      {
        id: "streetview_icon",
        label: "Leads",
        type: "other",
        name: "streetview_icon",
        title: "Leads",
      },
      {
        id: "MoreHorizIcon_icon",
        label: "More Option ",
        type: "other",
        name: "more_horiz_icon",
        title: "More Options",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "User List Search",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchFormDashbord",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Name",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "name",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_name",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 1,
            label: "Search By Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_email",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 2,
            label: "Search By State",
            name: "state",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_state",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 6,
            label: "Search By Stage",
            name: "stage",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-users-stage",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "latest_stage.stage_slug" },
          },

          {
            id: 7,
            label: "Search By Tag",
            name: "tag",
            type: "autoComplete",
            // multiple:true,
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-users-tag",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "latest_tag.tag_slug",param: "$in"},
          },

          {
            id: 8,
            label: "search by HM",
            name: "hm",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_tag",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 9,
            label: "search by BDD",
            name: "bdd",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
              },
              type: "user_tag",
              source: "users",
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 10,
            //   heading: "Search By Creation Time",
            label: "Search by Joining Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 11,
            label: "Search by Joining End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });





  useEffect(() => {
    if (!resolved) {
      store.dispatch(getDataFoAdminDashboardCard(reqbody));
    }
  }, [resolved]);

  useEffect(() => {
    let reqBody = { ...reqbody };
    reqBody["source"] = "users";
    reqBody["type"] = "user_created";
    store.dispatch(getDataFoAdminDashboardGridList(reqBody));
    store.dispatch(getDataFoAdminDashboardChart(reqbody));

    return () => {
      store.dispatch(clearAdminDashboardData());
    };
  }, []);

  useEffect(() => {
    console.log("adminCardData", adminCardData);

    if (adminCardData && adminCardData.length > 0) {
      let stsrtTtime = moment(value[0]).valueOf();
      let endTtime = moment(value[1]).valueOf();
      // console.log("singleTestimonial ------------>", singleTestimonial);
      console.log(stsrtTtime, endTtime, "date");
      let domNodeArr = null;
      let reqbody = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          // "_id": userID,
          createdon_datetime: {
            $gte: mindate,
            $lte: maxDate,
          },
        },
        project: {},
        token: "",
        count: false,
      };
      setTimeout(() => {
        domNodeArr = document.querySelectorAll("user-card ");
        console.log("domNodeArr", domNodeArr);
        if (domNodeArr && domNodeArr.length > 0) {
          for (let i = 0; i < domNodeArr.length; i++) {
            if (domNodeArr[i])
              domNodeArr[i].addEventListener("Summere-Card-Event", (e) => {
                let eventData = JSON.parse(
                  e.target.getAttribute("element-Event-Data")
                );
                console.log("eventData", eventData);
                let reqBody = { ...reqbody };
                switch (eventData) {
                  case "Users Created":
                    setselectedBlock("Users Created");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    setfilteredGridData([]);
                    reqBody["source"] = "users";
                    reqBody["type"] = "user_created";
                    store.dispatch(getDataFoAdminDashboardGridList(reqBody));
                    store.dispatch(getDataFoAdminDashboardChart(reqBody));
                    setScroll(true);
                    break;
                  case "Opportunity Created":
                    setselectedBlock("Opportunity Created");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    setfilteredGridData([]);
                    reqBody["source"] = "google_events";
                    reqBody["type"] = "opportunities";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);
                    break;
                  case "Follow Up Call Added":
                    setselectedBlock("Follow Up Call Added");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    setfilteredGridData([]);
                    reqBody["source"] = "follow_up_calls";
                    reqBody["type"] = "follow_up_call";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);
                    console.log("listData", listData);

                    break;
                  case "Conversion Created":
                    {
                      // setOpenMoadl(true);
                    }
                    break;
                  case "Training Completed":
                    setselectedBlock("Training Completed");
                    {
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      reqBody["source"] = "users";
                      reqBody["type"] = "training";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Appointment Booked":
                    setselectedBlock("Appointment Booked");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    setfilteredGridData([]);
                    reqBody["source"] = "google_events";
                    reqBody["type"] = "lead_appointment";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);

                    break;
                  case "Accessed Lead Folder":
                    setselectedBlock("Accessed Lead Folder");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    setfilteredGridData([]);
                    reqBody["source"] = "folder_access_records";
                    reqBody["type"] = "folder_access_lead";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);

                    break;
                  case "Accessed REP Folder":
                    {
                      setselectedBlock("Accessed REP Folder");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      console.log("rep working");
                      reqBody["source"] = "folder_access_records";
                      reqBody["type"] = "folder_access_rep";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;

                  case "Email Sent":
                    {
                      setselectedBlock("Email Sent");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      console.log("rep working");
                      reqBody["source"] = "assign_emails";
                      reqBody["type"] = "email_sent";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;

                  case "Information Link Sent":
                    {
                      setselectedBlock("Information Link Sent");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      reqBody["source"] = "assign_info_links";
                      reqBody["type"] = "info_link_sent";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Discovery Call with REP":
                    {
                      setselectedBlock("Discovery Call with REP");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      reqBody["source"] = "calendar_bookings";
                      reqBody["type"] = "discovery_call_rep";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Discovery Call with Lead":
                    {
                      setselectedBlock("Discovery Call with Lead");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      reqBody["source"] = "calendar_bookings";
                      reqBody["type"] = "discovery_call_lead";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "All Activities":
                    {
                      setselectedBlock("All Activities");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      setfilteredGridData([]);
                      reqBody["source"] = "activity_list";
                      reqBody["type"] = "activity_deatails";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  default:
                    {
                      setOpenMoadl(true);
                      console.log("Inside Default Case of switch-case");
                    }
                    break;
                }
              });
          }
        }
      }, 1000);
    }
  }, [adminCardData]);

  useEffect(() => {
    if (bttnClicked) {
      setOpenMoadl(true);
    }
  }, [bttnClicked]);

  const closeModal = () => {
    setOpenMoadl(false);

    setFollowUpModal(false)
  };
  const openModal = () => {
    setOpenMoadl(true);
  };

  const closeModalForNotes = () => {
    setOpenMoadlforNotes(false);
  };

  const handelsearch = () => {
    setfilteredGridData([])
    store.dispatch(clearGridListName());
    store.dispatch(cleardashbordListdata());
    store.dispatch(clearAdminDashboardUserListData());
    let stsrtTtime = moment(value[0]).valueOf();
    let endTtime = moment(value[1]).valueOf();
    console.log("time", value, stsrtTtime, endTtime);

    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        // _id: userID,
        createdon_datetime: {
          $gte: stsrtTtime,
          $lte: endTtime,
        },
      },
      project: {},
      token: "",
      count: false,
      type:usePaylod?.type?usePaylod.type:"user_created",
      source:usePaylod?.source?usePaylod.source:"users"
    };
    store.dispatch(clearAdminDashboardData());

    store.dispatch(getDataFoAdminDashboardCard(reqbody));
    store.dispatch(getDataFoAdminDashboardGridList(reqbody));
    store.dispatch(getDataFoAdminDashboardChart(reqbody));
    store.dispatch(setMinMaxTime(value));
  };

  useEffect(() => {
    if (folderViewBtn) {
      const payload = {
        user_for: dataRef._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      // resolvefunction(navigate, "/folderview?id=" + dataRef._id)
      window.open(`/folderview?id=${dataRef._id}`, "_blank");
    }
    console.log("dataRef", dataRef);
  }, [folderViewBtn]);

  useEffect(() => {
    if (leadViewBtn) {
      window.open(`/lead-list/${dataRef.main_role}/${dataRef._id}`, "_blank");
    }
  }, [leadViewBtn]);

  useEffect(() => {
    if (gridData?.length > 0) {
      setfilteredGridData(gridData);

    }
  }, [JSON.stringify(gridData)]);


  useEffect(() => {
    if (searchGridData.length > 0) {
      dispatch(clearGridList());
      setfilteredGridData(searchGridData);
    }
  }, [searchGridData]);


  useEffect(() => {
    if (adminGridData.length > 0) {
      dispatch(clearGridList());
      setfilteredGridData(adminGridData);
    }
  }, [adminGridData]);

  useEffect(() => {
    if (scroll === true) {
      scrollToGridData.current.scrollIntoView({ behavior: "smooth" });
    }

    setTimeout(() => {
      setScroll(false);
    }, 1000);
  }, [scroll]);
  useEffect(() => {




// -----------------------------------Opening stage Modal--------------------------------------- 
    if (fetchedStateData.length > 0) {
      setStageModal(true)
    }
  }, [fetchedStateData])




//----------------------------------Opening Contract Modal ------------------------------------
  useEffect(() => {
    if (contractData.length > 1) {
      setContractPreviewModal(false)
      setContractDrawer(true)
    } else if (contractData.length == 1) {
      setContractDrawer(false)
      setContractPreviewModal(false)
      const payload = {
        "searchcondition": {
          "user_id": rowData._id,
          "contract_id": contractData[0]._id
        }
      }
      dispatch(fetchContractTemplate(payload))
    }
  }, [contractData])



  useEffect(() => {
    if (contractTemplateData && Object.keys(contractTemplateData).length > 0 && contractTemplateData.status !== "error") {
      setContractPreviewModal(true)
    }
  },[contractTemplateData])


  useEffect(() => {
    if (TagManageModalData.length > 0) {
      setTagManageModal(true)

    }
  }, [TagManageModalData])
  // // stage component active and deactive configration
  // useEffect(()=>{
  //   if(btnState){
  //     setshowstageList(true)
  //   }
  // },[btnState])
  // //-----------------------------------------------


  // // Tag component active and deactive configration
  // useEffect(()=>{
  //   if(btnState){
  //     setshowtagListModal(true)
  //   }
  // },[btnState])

  useEffect(() => {
    if (statusAction !== null && statusAction !== undefined) {
      dispatch(usermanagestatusUpdate({ "dataCopy": rowData, "status": statusAction == 1 ? true : false }));
    }

  }, [statusAction])





  return (
    <>
      <div>
        {(loaderForAdminData === true || loader) && <LinearProgress />}

        <h1 className="page_heading">Dashboard</h1>

        <Grid container spacing={1} className="dashboard_con">
          <Grid item md={12} xs={12}>
            <div className="dashboard_block1 dashboardCommon">
              <h2>
                <span>Welcome:</span>{" "}
                {userInfo.userinfo?.firstname +
                  " " +
                  userInfo.userinfo?.lastname}
              </h2>

              <h3>
                <span>Last Login:</span>{" "}
                {lastloginTime !== null
                  ? moment(Number(lastloginTime)).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )
                  : "N/A"}
              </h3>
            </div>
          </Grid>
        </Grid>

        <div className="daterangepicker_one ">
          <span className="dash_brd_date">
            {" "}
            {moment(Number(mindate)).format("MMMM Do ")} -{" "}
            {moment(Number(maxDate)).format("MMMM Do , YYYY ")}
          </span>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={(newValue) => {
                console.log("newValue111", newValue);
                newValue.forEach((val) => console.log(moment(val).valueOf()));

                setValue(newValue);
              }}
              // onChange={handleDateChange}

              onOpen={() => {
                // clearErrors(fielddata.name);

                //////////////////////// Removing Missing License Key Message /////////////////////
                setTimeout(() => {
                  if (
                    document.querySelector("daterangepicker_one") !=
                    undefined &&
                    document.querySelector("daterangepicker_one").style !=
                    undefined
                  )
                    document.querySelector(
                      "daterangepicker_one"
                    ).style.display = "none";

                  let e = document.getElementsByTagName("div");
                  for (let i = 0; i < e.length; i++) {
                    let textval = e[i].innerText;
                    if (
                      textval.includes("Missing license key") &&
                      textval.length < 30
                    ) {
                      e[i].style.display = "none";
                    }
                  }
                  if (
                    document.querySelector("daterangepicker_one") !=
                    undefined &&
                    document.querySelector("daterangepicker_one").style !=
                    undefined
                  )
                    document.querySelector(
                      "daterangepicker_one"
                    ).style.display = "block";
                }, 200);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>

          <div className="daterangepicker_view_btn">
            <Button onClick={() => handelsearch()}>View</Button>
          </div>
        </div>


{/*----------------------------------------------------------------------------------------- DashBoard cards-------------------------------------------------------------------------------- */}



        <div className="dashboard_con2">
          {adminCardData &&
            adminCardData?.map((item) => {
              return (
                <>
                  <div
                    className={
                      item.cardTitle == selectedBlock
                        ? "card_element dashboard_Icon_block dashboardCommon active"
                        : " card_element dashboard_Icon_block dashboardCommon inactive"
                    }
                  >
                    <user-card data={JSON.stringify(item)}></user-card>
                  </div>
                </>
              );
            })}
        </div>

{/*------------------------------------------------------------------------------------------ Dashboard Grid Data ------------------------------------------------------------------------------- */}


        <div ref={scrollToGridData} className="dashboard_con3">
          <div className="dashboard_con3_sub CarouselView">
            {(loaderForAdminData === true || loader) && <LinearProgress />}
            <h2>
              {gridListName ? `${gridListName} Grid  View` : "Grid  View"}
              <Tooltip title={`${gridListName} Grid  View`} placement="bottom">
                <QuestionMarkIcon className="gird_chart_tooltip" />
              </Tooltip>
            </h2>
            <Carousel showThumbs={false} autoPlay interval="5000" infiniteLoop>
              {filteredGridData.length > 0 ? (
                filteredGridData.map((rowgrid, ind) => (
                  <div className="divul" key={ind}>
                    <data-grid data={JSON.stringify(rowgrid)}></data-grid>
                    {(adminGridData.length > 0) ? (
                      <BtnsComponent
                      setGridSingelUser={setGridSingelUser}
                      setStatusBtnClick={setStatusBtnClick}
                      userInfo={userInfo}
                        rowgrid={rowgrid}
                        fromGrid={true}
                        openModal={openModal}
                        setOpenMoadlforNotes={setOpenMoadlforNotes}
                      />
                    ) : (
                      <CommonBtn
                        openModal={openModal}
                        setOpenMoadlforNotes={setOpenMoadlforNotes}
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="noDataAvailableGrid">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" />
                </div>
              )}
            </Carousel>
          </div>



{/* ------------------------------------------------------------------------- Dashboard Chart  -----------------------------------------------------------*/}

          <div className="dashboard_con3_sub">
            {(loaderForAdminData === true || loader) && <LinearProgress />}
            <h2>
              {gridListName ? `${gridListName} chart view` : " Chart View"}
              <Tooltip title={`${gridListName} chart view`} placement="bottom">
                <QuestionMarkIcon className="gird_chart_tooltip" />
              </Tooltip>
            </h2>
            {Object.keys(adminChartData).length > 0 && (
              <line-chart data={JSON.stringify(adminChartData)}></line-chart>
            )}
            {Object.keys(regulerChartData).length > 0 && (
              <line-chart data={JSON.stringify(regulerChartData)}></line-chart>
            )}
          </div>
        </div>



{/*----------------------------------------------------------- Tabile for Dashboard ----------------------------------------------------------------- */}



        {(loaderForAdminData === true || loader) && <LinearProgress />}
        {adminListData && !loader && (
          <div className="dashboard_con3 dashboard_con3_listview">
            <TableContainer component={Paper}>
              <ListingTable
                tableData={tableDataDashboard.current}
                dataset={adminListData}
                modifyHeaders={modifyTableHeaders}
              />
            </TableContainer>
          </div>
        )}

        {!(loaderForAdminData === true || loader) &&
          listType === "opportunities" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <OpportunityCreated
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "training" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <CompletedTrainingList
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "lead_appointment" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <LeadAppointment
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "folder_access_rep" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AccessREP
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "follow_up_call" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <FollowUpCalladded
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "folder_access_lead" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AccessLaed
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "email_sent" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <EmailSentList
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "info_link_sent" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <InformationLinkSentList
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "discovery_call_lead" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <DiscoveryCallwithLead
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}
        {!(loaderForAdminData === true || loader) &&
          listType === "discovery_call_rep" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <DiscoveryCallwithREPList
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}
        {!(loaderForAdminData === true || loader) &&
          listType === "activity_deatails" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AllActivitiesList
                setsearchBtnClick={setsearchBtnClick}
                listData={listData}
                setSearchGridData={setSearchGridData}
              />
            </div>
          )}

        {(loaderForAdminData === true || loader) && <TableSkeleton count={5} />}
      </div>


{/*---------------------------------------------------------------- All Modal start hear ----------------------------------------------------------------- */}



      {/* ------------------------upgrade role modales --------------------------------- */}
      {userRolesNewData.length > 0 ? (
        <UpgradeRoleModal
          open={upgradeRoleModal}
          tooglefunc={setUpgradeRoleModal}
          role={userRolesNewData}
          userId={rowData._id}
        />
      ) : ("")}


      {/*------------------------- Manage stage Modal-------------------------------*/}
      {stageModal ? (<ManageStageModal
        toogle={stageModal}
        tooglefunc={setStageModal}
        stage={stageModal}
        UserId={rowData._id}
        Userinfo={rowData}
      />) : ''}


      {/*------------------------ Contract Preview Modal----------------------------*/}
      {contractPreviewModal ? <ContractViewModal
        toogle={contractPreviewModal}
        tooglefunc={setContractPreviewModal}
        contractdata={contractTemplateData}
        userinfo={rowData}
        source="user"
      /> : ""}


      {contractDrawer ? (<ContactDrawer
        open={contractDrawer}
        setcontractdrawer={setContractDrawer}
        contractdata={contractData}
        userid={rowData._id}
        userinfo={rowData}
        className="contract-drawer"
        source="user"
      />) : "" }




      {hmSettingModal ? (<ManageHmSettingModal
        toogle={hmSettingModal}
        tooglefunc={setHmSettingModal}
        setModalResponse={setStatusAction}
        data={FetchHMSettingModalData}
      // UserId={UserId}
      />) : ""}

      {tagManageModal ? (<ConfigureTagsModal
        toogle={tagManageModal}
        tooglefunc={setTagManageModal}
        setModalResponse={setStatusAction}
        tags={TagManageModalData}
        UserId={rowData._id}
        Userinfo={rowData}
      />) : ""}

      {/* ---- configureRoundRobinModal------ */}
      {roundRobinModal ? (
        <ConfigureRoundRobinModal
          toogle={roundRobinModal}
          tooglefunc={setRoundRobinModal}
          setModalResponse={setStatusAction}
          data={roundRobinData}
        // UserId={roundRobinData._id}
        />
      ) : (
        ""
      )}

      {/* ------ followup ------ */}
      {followUpModal ? (
        <FollowUpcall
          open={followUpModal}
          close={closeModal}
          addedById={userInfo.userinfo._id}
          addedForId={dataCopy._id}
          listData={followUpCallList}
          loading={loaderForFollowUp}
        />
      ) : (
        ""
      )}
      {/* ------------------------ */}


      {/* ------ trainingStatusModal ----- */}
      {trainingDataModal ? (
        <TrainingStatusModal
          popupdata={trainingData}
          popuptoogle={trainingDataModal}
          userTrainingData={userTrainingData}
          popuptooglefunc={setTrainingDataModal}
        //   type="profileInfo"
        />
      ) : (
        ""
      )}
      

      {/* --------------------------UPgrade Role------------------------- */}
      {configureRoleModal && userRoles !== null ? (
        <ConfigureRoleModal
          toogle={configureRoleModal}
          tooglefunc={setConfigureRoleModal}
          setModalResponse={setStatusAction}
          userRoles={userRoles}
          UserId={dataCopy._id}
        />
      ) : ("")}

      {/* ---- Email Send Drawer --- */}

      {dataCopy.unsubscribe_email !== 1 && emailtemplateData !== null ? (
        <EmailSendDrower
          open={openDrowerForEmail}
          close={closeDrower}
          data={emailtemplateData}
          addedById={userInfo.userinfo._id}
          addedForId={multiMail ? multiIdData : dataCopy._id}
          loading={loaderForsendmail}
          submistionstate={submistionstate}
        />
      ) : (
        ""
      )}

      {/* ------ Status Chnage Modal ------ */}
      {multiStatusModal ? (
        <MultiStatusChangeModal
          className="confirmaModal"
          confrimationMsg={confrimationMsg}
          toogle={multiStatusModal}
          tooglefunc={setMultiStatusModal}
          setModalResponse={setStatusAction}
        />
      ) : (
        ""
      )}
      {/* -------------------------------- */}


      {/* ---- Last login Modal details ---- */}
      {loginPopupData != null && loginPopupData.length > 0 ? (
        <LastLoginModal
          popupdata={loginPopupData}
          popuptoogle={loginPopupToogle}
          popuptooglefunc={setloginPopupToogle}
        />
      ) : (
        ""
      )}
      {/* ------------------------- */}
      {fetchData ? (
        <PopupModal
          popupdata={popupData}
          popuptoogle={popupToogle}
          popuptooglefunc={setPopupToogle}
          type="profileInfo"
        />
      ) : (
        ""
      )}

     


      {/* -------- ShowStageListModal ------ */}

      {
        showstageList ? (<ShowStageListModal
          open={showstageList}
          toogle={showstageList}
          tooglefunc={setshowstageList}
          setModalResponse={setshowstageListAction}
          stagesdata={fetchStagelistData}
          UserName={dataCopy.fullname}
        />) : ''

      }



      {/* ---- ShowTagListModal------ */}
      {showtagListModal ? (
        <ShowTagListModal
          toogle={showtagListModal}
          tooglefunc={setshowtagListModal}
          setModalResponse={setStatusAction}
          tagsdata={taglifecycledatas}
          UserName={dataCopy.fullname}
        />
      ) : (
        ""
      )}

      {/* ---------------------- */}

      {openDrowerForInfoLink && <InformationSentOption
        open={openDrowerForInfoLink}
        close={closeDrowerForInfoLink}
        data={infoLinksData}
        addedById={addedById}
        addedForId={addedForId}
        loading={loaderForInformationSend}


      />}


      {openMoadlforNotes === true && (
        <DNotes
          // adminListData={adminListData}
          openMoadl={openMoadlforNotes}
          closeModal={closeModalForNotes}
        />
      )}




      <BtnDower
        setOpenDrowerForInfoLink={setOpenDrowerForInfoLink}
        setshowstageList={setshowstageList}
        setshowtagListModal={setshowtagListModal}
        setStatusBtnClick={setStatusBtnClick}
        setMultiStatusModal={setMultiStatusModal}
        setStageModal={setStageModal}
        moreButnClickOp={moreButnClickOp}
        moreBtnClickUser={moreBtnClickUser}
        openBtnDrower={openBtnDrower}
        handleCloseDrawer={handleCloseDrawer}
        openModal={openModal}
        setOpenMoadlforNotes={setOpenMoadlforNotes}
        dataRef={rowData}
        setContractPreviewModal={setContractPreviewModal}
      />





{/* -----------------------------------------------------------------------------------------Dashboard Defolt Modal----------------------------------------------------------------------- */}

<div>
        <Modal
          classname="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h1>Cooming Soon</h1>
          </Box>
        </Modal>
      </div>
    </>
  );
}