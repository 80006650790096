import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
 

import store from '../../../store'

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import ShowStageListMap from './ShowStageListMap';
 

// import { updateStages } from './LeadReducer'


const ShowStageListModal = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----


    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)
    const [UserId, setUserId] = useState();





    console.warn("<<<<<< ShowStageListModal props goes here >>>>>>", props)

    const loader = useSelector(state => (state.LeadReducer?.loading) ? state.LeadReducer?.loading : false)
    // const tagsDetails = useSelector(state => (state.LeadReducer?.FetchTagDetailsData) ? state.LeadReducer?.FetchTagDetailsData : [])
    
    // const showstageListData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer?.ShowStageListData : [])

    // const success = useSelector(state => (state.LeadReducer?.resMessage) ? state.LeadReducer?.resMessage : null)
    // const loggedUserId = useSelector((state) => state.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null)

    // useEffect(() => {
    //     if (success) {
    //         setopen(true)
    //     }
    // }, [success])


    // useEffect(() => {
    //     if (showstageListData.length > 0) {
    //         setOpenDropDown(false)
    //     }
    // }, [showstageListData])

    console.log(props.stagesdata, "showstagelist----")



    useEffect(() => {
        props.setModalResponse(false);
        // setUserId(props.UserId)
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);
        // setOpenDropDown(false)

    }




    // -------------   ------------------
    // useEffect(() => {
    //     if (clicked) {
    //         const data = {
             
    //             stage_slug: clicked.key,
    //             added_by: loggedUserId,
    //             added_for: UserId? UserId:undefined,
    //             is_delete: clicked.status === 0 ? 0 : 1,
    //             status: clicked.status === 0 ? 1 : 0
    //         }
    //         console.log("data===========================>", data)

    //         dispatch(updateStages(data));
    //         setOpenDropDown(false)
    //     }

     
    // }, [clicked])


    // useEffect(() => {
    
    //     console.log('updateStages',updateStages)
     
    // }, [])
    
    
 

    // ----------------------------


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    console.log(props.UserName, "userName...>>>>>")


    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock forLife_Cycle"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" 
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h6">
                        {loader ? <LinearProgress /> : ""}

                      {/* Stage List for: {props.UserName} */}
                      Stage List for: {props.UserName}

                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <div className="showstageListwrpr">
                                    {/*---------map compoenet */}
                                
                                    {props.stagesdata && props.stagesdata.length>0?  <ShowStageListMap dataset={props.stagesdata}/>:<div>NO data found!</div>}  
                        </div>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {/* {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={success}
            action={action}
            />) : ""} */}

        </>
    )
}


export default ShowStageListModal;