import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../../../store";
import CloseIcon from "@mui/icons-material/Close";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Grid, IconButton, List, Tooltip } from "@mui/material";
import { Box, display } from "@mui/system";
// import { useCookies, Cookies } from "react-cookie";


const AssociateBbdHmInfoModal = (props) => {
  const dispatch = useDispatch(); // ---- Dispatch -----
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

  // ---- States ----
  const [loading, setLoading] = useState(true); //*****FOR LODING****//

  // ---- Cookies ---
  // const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

  // // ---- UseSelectors ----
  // const users = useSelector((state) => state.userManagment.usersList)
  // const usersToken = useSelector((state) => state.loggedinuser.usertoken)

  console.log("<<<<< props from popupToogle modal compo >>>>", props);

  // ------- onModalClose --------
  function ModalClose() {
    props.popuptooglefunc(false);
  }



  return (
    <>
      {/* ------ Confirmation Modal ------ */}

      <Modal
        className=" modalblock userList_modal"
        open={props.popuptoogle}
        onClose={ModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={"BoxMui_modal other_BoxMui_modal"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >

          <div className=" ModalBox_scroll">
            <div className="heading">
              <h3>
                BBD/HM Information For : {props?.rowdata?.name? props.rowdata.name: props.rowdata.fullname }
              </h3>
            </div>





            {/* {props?.data?.length > 0 ? */}
             { props.data.map((x) => 

                <ul className="listflexblocknew" key={x._id}>
                  {x.roles.hr_manager==1? <h3 className="heading"> Hiring Manager Details  </h3> : <h3 className="heading"> BDD Details  </h3>}

                  <li>
                    <strong>Full Name :</strong>{" "}
                    <span>{x.firstname + " " + x.lastname}</span>
                  </li>


                  <li>
                    <strong>E-mail :</strong>{" "}
                    <span className="emailText">{x.email}</span>
                  </li>



                  <li>
                    <strong>Contact :</strong>{" "}
                    <span>{x.phone}</span>
                  </li>

                </ul>
              ) }
              {/* : "Abcfds"} */}

          </div>



          <Tooltip title="Close" placement="right-start">
            <IconButton className="modalCloseBtn" onClick={ModalClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>


        </Box>
      </Modal>
    </>
  );
};

export default AssociateBbdHmInfoModal;
