import {
  IconButton,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../Dashboard/Dashboard.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useCookies } from "react-cookie";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getDataForNotes } from "../../Dashboard/DashboardReducer";
import ListingTable from "../../../../listing/listing";
import { Form } from "../../../../form/Form";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import { useParams } from "react-router-dom";


export default function FDNotes({ openMoadl, closeModal, noteData }) {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies();
  const { id } = useParams(); // Here id is the leadid getting from route params

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const leadData = useSelector(state => state.LeadReducer?.editleaddata && state.LeadReducer?.editleaddata.length > 0 ? state.LeadReducer.editleaddata[0] : null);
  const DNotesData = useSelector(state => state.DashboardReducer?.DNotesData ? state.DashboardReducer.DNotesData : {});
  const loading = useSelector(state => state.DashboardReducer?.loading ? state.DashboardReducer.loading : false);
  const loggedUserId = useSelector((state) => state?.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null);

  // --------------------------------------------Other Veriables-------------------------------------------- //
  const dataform = {
    id: "notesAdd",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "note/add-note",
    urlPathOnSuccessfulFormSubmission: "",
    submitBtnName: "Add Note",
    // cancelBtnName: "Cancel",
    className: "inputBlock inputBlockline",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
      notes_for: id,  // Here id is the leadid
      notes_by: cookies?.userInfo?._id ? cookies.userInfo._id : loggedUserId,
      collection_name: 'notes_list',
    },

    fields: [
      {
        id: 1,
        // heading: "Notes",
        placeholder: "Type your notes here...",
        name: "notes",
        type: "textarea",
        className: "inputBlock inputBlock1line",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 2,
        heading: "Upload file",
        name: "upload_file",
        className: "inputBlock inputBlock1line",
        type: "fileUploader",
        multiple: false,
        accept: ".pdf,image/*,.doc,.docx,video/*,audio/*", //////// Write properly /////////
        bucket: "uploaded-files-react",
        path: "aspireuploadimage/",
        base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        endpoint: "requestUploadURL",
        deleteEndpoint: "deletefilefromBucket",
      },
    ],
    customButtons: [],
  };
  console.log("data for notes in notea", DNotesData);

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
      count: false,
    };
    dispatch(getDataForNotes(reqbody));
  }, []);

  //----------------------------------------------return-----------------------------------------------//
  return (
    <>
      {!loading &&
        <div>
          <Modal
            className="image_card_modal modalBaseStyle"
            open={openMoadl}
            onClose={closeModal}
            closeAfterTransition
          >
            <Box className="modalBaseBox largeModal">
              <IconButton className="modal_icon_button" onClick={closeModal}>
                <CloseIcon className="modal_close_icon" />
              </IconButton>
              <h2 className="notes_details_custom_head">Notes For: {leadData?.fullname}</h2>

              <div className="dataform2_wrapper">
                <div className="adminformbody">
                  <Form formData={dataform} />
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      }
    </>
  );
}