import React, { useEffect, useState } from "react";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from "react-redux";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { format } from 'date-fns'
import { getEmailTeamplatedata } from "../../EmailTemplateManagement/EmailTemplateReducer";
import EmailSendDrower from "../../LeadManagement/EmailSendDrower";
import { useCookies } from "react-cookie";
import store from "../../../../store";
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from "./FDSearch";
import { UserActivity } from "./folderViewReducer";
import { useLocation } from "react-router-dom";




const EmailVew = ({ emailData, showDiv,  emailFor }) => {

  console.log("emailview1",emailData);
  console.log("emailview2",showDiv);
  console.log("emailview3",emailFor);



  //----------state defines-----------------
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [dataCopy, setDataCopy] = useState({});
  const [openDrowerForEmail, setOpenDrowerForEmail] = useState(false);
  const [queryParamId, setQueryParamId] = useState(); 
  //---------------------------------------
  const dispatch = useDispatch();

  const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});
  const loading = useSelector(state => (state?.EmailTemplateReducer?.loading) ? state.EmailTemplateReducer.loading : false)
  const emailtemplateData = useSelector(state => (state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0) ? state.EmailTemplateReducer.emailtemplateListData : []);
  const loaderForsendmail = useSelector(state => state.LeadReducer?.loaderForsendmail);
  const submistionstate = useSelector(state => state.LeadReducer?.submistionstate);
  // console.log("showDiv",emailData);

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})


  // ----------------
    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
  
 
    const paramId = queryParam.search.split("=");
  
    console.log("data paramId",paramId);
  
    useEffect(() => {
      if (paramId[1]) {
        setQueryParamId(paramId[1])
      }
    }, [JSON.stringify(paramId)])
  
    // -------------------------------


  useEffect(() => {
    setOpenDrowerForEmail(false)
  }, [])

  const HandelEmail = () => {

    let reqbody = {
      "condition": {
        "limit": 5,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": { roles: { $in: [emailFor] } },
      "count": false,
      "source": "",
    }
    setDataCopy(dataRef)
    store.dispatch(getEmailTeamplatedata(reqbody));

  }

  useEffect(() => {

    if (emailtemplateData.length > 0) {
      setOpenDrowerForEmail(true)
    }

  }, [emailtemplateData])

  const closeDrower = () => {
    setOpenDrowerForEmail(false)
  };
//----------------------From Input Fields for Search---------------------------
  const searchFields_EmailSend = [
    {
      id: 0,
      label: "Search By Email Sent  ",
      name: "activity_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-email-sendby",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          
          "user_id":queryParamId?queryParamId:undefined
        },
        "project": {},
        "token": "",
        "email": ""
      },
    },
    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  const searchFields_EmailSendBy = [
   
    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
//--------------------------------------------------------------------------

//-------------------get from search fields-------------------------------
  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];
     
      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_by": searchData?.activity_by ? searchData.activity_by : undefined,
          "activity_time": activity_time


        },
        "project": {},
        "token": "",
        "email": ""
      }
      activity_time.$gte == " " || activity_time.$gte == undefined && activity_time.$lte == " " || activity_time.$lte == undefined  && delete payload.searchcondition["activity_time"];
      dispatch(UserActivity(payload))

      showDiv !== "EmailSend" &&  delete payload?.searchcondition["activity_by"];
    }
  }, [submissionState])
//-----------------------------------------------------------------------------

  return (
    <>
      <div>
        {showDiv == "EmailSend" && (
          <h3>
            <ForwardToInboxIcon /> Email Send{" "}
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Send Email">
                <IconButton
                  className="filtersearch"
                  onClick={() => HandelEmail()}
                >
                  <ForwardToInboxIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Search">
                <IconButton>
                  <SearchIcon onClick={() => setSearchDraw(true)} />
                </IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {showDiv == "EmailSendBy" && (
          <h3>
            <MailOutlineIcon /> Email Send By
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

              <Tooltip title="Send Email">
                <IconButton>
                  <SearchIcon onClick={() => setSearchDraw(true)} />
                </IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {emailData.length > 0 &&
          emailData.map((x) => (
            <div className="user-activity" key={x._id}>
              {showDiv == "EmailSend" && (
                <ul className="ua-data-block">
                  <li>Mail Name: {x.email_subject} </li>
                  <li>Mail Sent Time: {x.createdon_datetime} </li>
                  <li>Mail Sent By: {x.sent_by_name} </li>
                  <li>Recipient: {x.sent_to_name} </li>

                  <li>
                    Email Status :{" "}
                    {x.email_open === 1
                      ? x?.email_opened_at
                        ? `Email Open On ${format(
                          x?.email_opened_at,
                          "dd-MM-yyyy HH:mm:ss"
                        )}`
                        : " "
                      : "Email deliverd but not open yet"}
                  </li>
                  <li>
                    Mail Tracking: {x.email_tracking ? "Active" : "Inactive"}{" "}
                  </li>
                </ul>
              )}
              {showDiv == "EmailSendBy" && (
                <ul className="ua-data-block">
                  <li>Mail Name: {x.email_subject} </li>

                  <li>Mail Sent Time: {x.createdon_datetime} </li>

                  <li>Mail Sent To: {x.sent_to_name} </li>
                  <li>Mail Sent By: {x.sent_by_name} </li>
                  {/* <li>Email Status : {x.email_open===1 ? `Email Open On ${format(x?.email_opened_at, 'dd-MM-yyyy HH:mm:ss')}`:"Email deliverd but not open yet"}</li> */}
                </ul>
              )}
            </div>
          ))}
        {emailData.length === 0 && <p> No Data Found !</p>}
      </div>


      {/* ---- Email Send Drawer --- */}
      {/* {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null} */}
      {openDrowerForEmail ? <EmailSendDrower
        open={openDrowerForEmail}
        close={closeDrower}
        data={emailtemplateData}
        addedById={userInfo?.userinfo?._id}
        addedForId={queryParamId}
        loading={loaderForsendmail}
        submistionstate={submistionstate}
      /> : ""}
      {/* --------------------------- */}
      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        searchFields={showDiv==="EmailSend"?searchFields_EmailSend:searchFields_EmailSendBy}
        type="email " /> : ""}
    </>
  );
};

export default EmailVew;
