import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, TextField, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';
import FDSearch from './FDSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
const UserActivityBlock = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 

  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // ---- States here -----
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [open, setOpen] = React.useState(false); // snackbar const
  const [value, setValue] = React.useState([null, null]);

  //------search drawer------
  // if(searchData){
  //   setSearchDraw(false)
  // }
  //----------------------

  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();

  const paramId = queryParam.search.split("=");

  console.log("data paramId", paramId);

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------


  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


  // --- loader --- 
  const loader = useSelector(state => (state.fetchUserDataReducer?.loading
  ) ? state.fetchUserDataReducer.loading
    : null);

  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

  const loading = useSelector(state => (state?.userFolderViewReducer?.waiting) ? state.userFolderViewReducer.waiting : false)

  const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

  console.log("<<<<<<<<< searchData >>>>>>>>>>>", searchData);

  //-------------fetch Start_Date=mindat End_Date=masdate--------------
  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );
  //---------------------------------------------------------------------



  //------------Fetch data from User Activity search Fields ------------------

  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)

      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];




      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_by": searchData?.activity_by ? searchData.activity_by : undefined,
          "activity_type": searchData?.activity_type ? searchData.activity_type : undefined,
          "activity_time": activity_time

        },
        "project": {},
        "token": "",
        "email": ""
      }

      activity_time.$gte == " " || activity_time.$gte == undefined && activity_time.$lte == " " || activity_time.$lte == undefined && delete payload.searchcondition["activity_time"];

      dispatch(UserActivity(payload))
    }
  }, [submissionState])
  // -------------------------------------------------------------------------


  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setErrPopup(false);
    // setErrMsgCopy(null);
  };
  //---------------------------- Input Fields For Search-----------------------------
  const searchFields = [
    {
      id: 0,
      label: "Search  Activity By ",
      name: "activity_by",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, width: 600 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activityby",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {

          "user_id": queryParamId ? queryParamId : undefined
        },
        "project": {},
        "token": "",
        "email": ""
      },

    },

    {
      id: 1,
      label: "Search by Activity Type",
      name: "activity_type",
      className: 'inputBlock inputBlock3line',
      type: "autoComplete",
      sx: { m: 1, minWidth: 300 },
      base_url: process.env.REACT_APP_API_URL + "activity/autocomplete-activity-type",
      reqBody: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "field": "_id",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined

        },
        "project": {},
        "token": "",
        "email": ""
      },

    },


    {
      id: 2,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, minWidth: 300 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 3,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, minWidth: 300 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]
  //--------------------------------------------------------------------


  return (
    <>
      <div>
        <h3>
          <PersonIcon /> {props?.name} Activity
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search User Activity">
              <IconButton onClick={() => setSearchDraw(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: 'Check-in', end: 'Check-out' }}
                    >
                        <DateRangePicker
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider> */}
        </h3>
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        <div className="UA-scroll-activity">
          {props.activity?.length > 0 ? (
            props.activity?.map((x) => (
              <div key={x?._id} className="user-activity">
                <ul className="ua-data-block">
                  <li className="heading">
                    {" "}
                    {x.activity_type == "login" ? (
                      <LoginIcon />
                    ) : x.activity_type == "tag_assigned" || x.activity_type == "tag_assigned_by" ? (
                      <LocalOfferIcon />
                    ) : x.activity_type == "folder_accessed" ||
                      x.activity_type == "folder_accessed_by" ? (
                      <FolderIcon />
                    ) : x.activity_type == "email_sent" ||
                      x.activity_type == "email_sent_by" ? (
                      <MarkEmailReadIcon />
                    ) : x.activity_type == "Log out complete" ? (
                      <LogoutIcon />
                    ) : x.activity_type == "stage_assigned_by" ||
                      x.activity_type == "stage_assigned" ? (
                      <ClearAllIcon />
                    ) : x.activity_type == "Get Contract" ? (
                      <TextSnippetIcon />
                    ) : x.activity_type == "email_open_by" ? (
                      <DraftsIcon />
                    ): (
                      " "
                    )}{" "}


                    {x.activity_type ? x.activity_type.split('_').join(' ') : "N/A"}{" "}
                  </li>
                  <li>
                    Activity Time:{" "} {format(x.activity_time, "dd-MM-yyyy HH:mm:ss")}{" "}
                  </li>
                  <li>Activity: {x.activity}</li>
                </ul>
              </div>
            ))
          ) : (
            <p> No activity found!</p>
          )}
        </div>
      </div>
      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="user_activity" /> : ""}
    </>
  );
}

export default UserActivityBlock;






