import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Dialog, IconButton, Snackbar, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { Form } from '../../../form/Form';
import { setSnackbar } from '../../Layout/layoutReducer';
import CampaingList from './../../CampaignManagement/CampaingList';
import ListingTable from '../../../listing/listing';

import {
  clearcampaignDatadata,
  cleareditdata,
  getCampaignEdit,
  getCampaignListData,
} from "../../CampaignManagement/CampaignReducer";
import store from '../../../store';
import { TableSkeleton } from '../../../helper/TableSkeleton';
import copy from "copy-to-clipboard";
import { CookiesProvider, withCookies, Cookies, useCookies } from "react-cookie";




function CampainManageModal({ open, close, user_id, user_name, landingpage_id }) {
  // console.log("ALL DATA OF CAMPAIGN ======>>", landingpage_id)
  const cookies = new Cookies();
  const [getAllcookies, setgetcookies] = useState(cookies.getAll());
  const dispatch = useDispatch();
  const [addflag, setaddflagfun] = useState(false);
  const [addClickflag, setaddClickflagfun] = useState(false);
  const [editflag, editflagfun] = useState(false);
  const campaignformSubmissionState = useSelector((state) => state.formSlice.formSubmissionState.CampaignAddEdit ? state.formSlice.formSubmissionState.CampaignAddEdit : 0)

  let campaingDataforlist = useSelector((state) => state.camPaignManagementReducer?.campaignData && state.camPaignManagementReducer?.campaignData.length > 0 ? state.camPaignManagementReducer.campaignData : []);


  let loader = useSelector((state) =>
    state.camPaignManagementReducer?.loading
      ? state.camPaignManagementReducer.loading
      : false
  );



  const editClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.campaigntdataTable?.edittable9
      ? state.tableSlice.customButtonClicked.campaigntdataTable.edittable9
      : false
  );

  const editData = useSelector((state) =>
    state.tableSlice?.tempRowData?.campaigntdataTable
      ? state.tableSlice.tempRowData.campaigntdataTable
      : {}
  );
  const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);


  // ############# FOR COPY TO CLIP BOARD ################## //
  const copyClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.campaigntdataTable?.cpoyurlbtn
      ? state.tableSlice.customButtonClicked.campaigntdataTable.cpoyurlbtn
      : false
  );

  const copyToClipboard = (e) => {
    copy(e);
    dispatch(setSnackbar({ open: true, message: "Copied To Clipboard !", status: 'success' }))
  }

  useEffect(() => {
    console.warn("COPY DATA =======>", editData)
    if (copyClicked && Object.keys(editData).length > 0) {
      let copyVal = landingpage_id.p_url;
      copyVal = copyVal + user_data.user_name + editData.sub_id;
      copyToClipboard(copyVal)
    }

  }, [copyClicked]);





  const addflagback = useSelector(state => state.formSlice?.customButtonClicked?.CampaignAddEdit?.addflagback ? state.formSlice.customButtonClicked.CampaignAddEdit.addflagback : false); // ########### Click Function For GO BACK Button ########### //
  useEffect(() => {
    if (addflagback) {
      setaddflagfun(false);
      //console.log('addflagback', addflagback)
    }
    if (landingpage_id.edit_flag) {
      setaddflagfun(true);
    }
  }, [addflagback])


   // ############## CALL ENDPOINT FOR COUNT ############### //
  let reqbodyofList = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "updatedon_datetime",
      type: "desc",
    },
    searchcondition: {
      "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
      // "user_name": user_name,
      "user_id": user_id
    },
    project: {},
    token: "",
  };

  useEffect(() => {
    // console.log("RoleList", resolve);
    store.dispatch(getCampaignListData(reqbodyofList));

    return () => {
      store.dispatch(clearcampaignDatadata());
    };
  }, []);


//  ################ ADD EDIT FORM ################ // 
  const dataform = {
    id: 'CampaignAddEdit',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "campaign-manage/add-update-campaign",
    urlPathOnSuccessfulFormSubmission: "",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
      // "_id":(editData && Object.keys(editData).length > 0 && editData._id !== undefined) ? editData._id : undefined,
      "user_id": user_id,
      "user_name": user_name,
      "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id
    },

    fields: [

      {
        id: 0,
        heading: "Campaign Name",
        lable: "Campaign Name",
        name: "name",
        className: "inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.name !== undefined) ? editData.name : undefined,

      },

      {
        id: 2,
        heading: "Description",
        name: "description",
        className: "inputBlock inputBlock1line",
        type: 'textarea',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.description !== undefined) ? editData.description : undefined,
      },

      {
        id: 4,
        heading: "",
        lable: "",
        name: "status",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active' },
        defaultValue: (editData && Object.keys(editData).length > 0 && editData.status !== undefined) ? editData.status : undefined,
      },
      {
        id: 5,
        heading: "_id",
        name: "_id",
        className: "inputBlock inputBlock1line",
        type: 'hidden',
        defaultValue: (editData && Object.keys(editData).length > 0 && editData._id !== undefined) ? editData._id : undefined,
      },
    ],

    customButtons: [

      {
        id: 'addflagback',
        label: ' Back to List',
        className: 'default',
        type: 'button',
      },

    ]

  }


  useEffect(() => {

    if (campaignformSubmissionState && campaignformSubmissionState == 2) {
      if (editflag) {
        dispatch(setSnackbar({ open: true, message: "Campaign Edited Successfully !", status: 'success' }));
      }
      else {
        dispatch(setSnackbar({ open: true, message: "Campaign Added Successfully !", status: 'success' }));
      }
      // close();
      store.dispatch(getCampaignListData(reqbodyofList));
      setaddflagfun(false);
    }

  }, [campaignformSubmissionState])



  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      console.warn("editData>>>>>>>>>>>", editData);
      setaddflagfun(true);
      editflagfun(true);
    }
  }, [editClicked]);

  var modifyTableHeaders = [
    { val: "name", name: "Campaign Name" },
    { val: "landing_page_name", name: "Landing" },
    {
      val: "status",
      name: "Status",
      customVal: { 0: "Inactive", 1: "Active" },
    },

    {
      val: "updatedon_datetime",
      name: "Updated On",
      type: "datetime",
      format: "DD/MM/YYYY",
    },
  ];

  let tableDataCampaign = useRef({
    tableId: "campaigntdataTable",
    tableTitle: "Campaign List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "campaign-manage/user-list-or-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "campaign-manage/user-list-or-count",
    },

    reqBody: {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "updatedon_datetime",
        type: "desc",
      },
      searchcondition: {
        "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
        // "user_name": user_name,
        "user_id": user_id
      },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },


      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "cpoyurlbtn",
        type: "other",
        icon_type: "content_copy_icon",
        label: "Copy Url",
        name: "content_copy_icon",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    // searchBarData: {
    //   heading: "Search Campaign ",
    //   formButtonClass: "submitbuttonwrapper test searchRefreshIconBtn",
    //   api: {
    //     url: process.env.REACT_APP_API_URL,
    //     endPoint: "campaign-manage/user-list-or-count",
    //     tableCountEndpoint: "campaign-manage/user-list-or-count",
    //     reqBody: {
    //       condition: {
    //         limit: 5,
    //         skip: 0,
    //       },
    //       sort: {
    //         field: "_id",
    //         type: "desc",
    //       },
    //       searchcondition: {
    //         "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
    //         //"user_name": user_name,
    //         "user_id": user_id
    //       },
    //       project: {},
    //       token: "",
    //       count: false,
    //     },
    //   },

    //   searchSettings: {
    //     datesearch: [
    //       {
    //         id: 0,
    //         heading: "Search By Created On Time",
    //         startdatelabel: "Start Date",
    //         enddatelabel: "End Date",
    //         className: "inputblock2line",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //       {
    //         id: 1,
    //         heading: "Search By Updated On Time",
    //         startdatelabel: "Start Date",
    //         enddatelabel: "End Date",
    //         className: "inputblock2line",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //     ],
    //     selectsearch: [
    //       {
    //         id: 2,
    //         // heading: "Search By Status",
    //         label: "Search By Status",
    //         field: "status",
    //         type: "select",
    //         className: "inputblock2line",
    //         values: ["Active", "Inactive"],
    //         // value: "",
    //       },
    //     ],

    //     autoCompleteSearch: [
    //       {
    //         id: 3,
    //         heading: "Search By Campaign Name",
    //         label: "Search By Campaign Name",
    //         field: "name",

    //         //  values: ["Surgical Wound","Pressure Injuries"],
    //         // values: "",
    //         preload: true,
    //         multiple: false,
    //         className: "inputblock2line",
    //         serverSearchData: {
    //           base_url: process.env.REACT_APP_API_URL,
    //           endpoint: "campaign-manage/auto-complete-campaign",
    //           reqBody: {
    //             source: "campaign_list",
    //             sort: {
    //               type: "asc",
    //               field: "key",
    //             },
    //             searchcondition: {},
    //           },
    //         },
    //       },
    //     ],
    //     textsearch: [

    //     ],
    //   },
    // },

    searchData: {
      heading: "Search Campaign",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Campaign Name",
            name: "campaign_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign",
            reqBody: {
              "source": "campaign_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {
                // "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
                // //         //"user_name": user_name,
                // "user_id": user_id
              }
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_id" },

          },
          {
            id: 0,
            label: "Search By Landing Page Name",
            name: "landing_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            // base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign",
            // reqBody: {
            //   "source": "campaign_list",
            //   "condition": {
            //     "limit": 10,
            //     "skip": 0
            //   },
            //   "sort": {
            //     "type": "asc",
            //     "field": "name"
            //   },
            //   "searchcondition": {

            //   }
            // },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "landing_page_id" },

          },
          // {
          //   id: 2,
          //   label: "Search by Parent Category Name",
          //   name: "training_parent",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "training-manage/auto-complete-training",
          //   reqBody: {
          //     "source": "training_list",
          //     "condition": {
          //       "limit": 10,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "asc",
          //       "field": "name"
          //     },
          //     "searchcondition": {

          //     }
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "training_parent" },

          // },
          // {
          //   id: 5,
          //   label: "Search by Program/Product Name",
          //   name: "product",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "program-manage/fetch-programs",
          //   reqBody: {
          //     "source": "training_list",
          //     "condition": {
          //       "limit": 10,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "asc",
          //       "field": "name"
          //     },
          //     "searchcondition": {

          //     }
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "product" },

          // },

          {
            id: 3,
            label: "Search by Status",
            name: "status",
            // className: 'Status',
            type: 'select',
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "status" },
          },

          {
            id: 8,
            label: "Search by Updation Start Date",
            name: "updatedon_Start_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Updation End Date",
            name: "updatedon_datetime",
            className: 'list_search_item countDiv__column--col4 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            format: 'dd/MM/yyyy',
            payloadFormat: { key: "updatedon_datetime", param: "$lte" }
          },
          // {
          //   id: 10,
          //   label: "Search by Date Range",
          //   name: "createdon_datetime",
          //   className: 'list_search_item countDiv__column--col4 ',
          //   type: 'dateRangePicker',
          //   sx: { m: 1, width: 300 },
          //   // format: 'dd/MM/yyyy'
          // },
        ]
      }
    },


  });


  const addModal = () => {
    setaddClickflagfun(true);
    setaddflagfun(true);
  }

  const closeModal = () => {
    setaddflagfun(false);
    close();
  }
  return (
    <div>

      <div>

        <Dialog
          classname="hmmodal"
          open={open}
          onClose={() => closeModal()}>

          <Tooltip title='Close' >
            <Button onClick={() => closeModal()} className="hmmodalCloseBTN"> <CloseIcon /> </Button>
          </Tooltip>
          <Box className='hmmodal_user_Box' >

            {addflag ?
              <div className='hmmodal_user_Box_wrapper' >
                {editflag ? <h3>Edit Campaign ({landingpage_id.p_name})</h3> : <h3>Add Campaign ({landingpage_id.p_name})</h3>}
                <Form formData={dataform} />
              </div> :
              <div className='hmmodal_user_Box_wrapper' >

                <h4><span>Manage Campaign   ( {landingpage_id.p_name} )</span> <Tooltip title="Add Campaign">
                  <button
                    className="addCampaign_btn"
                    onClick={() => addModal()}
                  >
                    +
                  </button>
                </Tooltip> </h4>

                <div className="rolelist_mainwrp">
                  <div className="rolelist_mainwrp_Table">
                    {!loader ? (
                      <ListingTable
                        tableData={tableDataCampaign.current}
                        dataset={campaingDataforlist}
                        modifyHeaders={modifyTableHeaders}
                      />
                    ) : (
                      <TableSkeleton count={5} />
                    )}
                  </div>
                </div>

              </div>
            }

          </Box>
        </Dialog>
      </div>
    </div>
  )
}


export default CampainManageModal