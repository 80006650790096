import {Drawer} from '@mui/material'
import React, { useEffect, useState } from 'react'
import BtnsComponent from './BtnsComponent'
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, Tooltip } from "@mui/material";
import OpBtnsComponent from './OpButtonComponent';

const BtnDower = (
  {openBtnDrower,
    setStatusBtnClick,
    handleCloseDrawer,
    openModal,
    setOpenMoadlforNotes,
    dataRef,
    setContractPreviewModal,
    moreButnClickOp,
    moreBtnClickUser,
    setshowstageList,
    setshowtagListModal,
    setOpenDrowerForInfoLink,
    setMultiStatusModal,
    setStageModal,
    setUpgradeRoleModal
  }) => {


  const[userBtn,setUserBtn] = useState(false)
  const[opBtn,setOpBtn] = useState(false)

useEffect(()=>{
  if(moreButnClickOp){
    setOpBtn(true)
  }else if(moreBtnClickUser){
    setUserBtn(true)
  }
},[moreButnClickOp,moreBtnClickUser])
  

  return (
    <>
    <Drawer
        anchor={"bottom"}
        open={openBtnDrower}
        onClose={handleCloseDrawer}
        >
    <Paper
      elevation={7}
      className="list_search team_management_list_search search_img"
      style={{paddingBottom:"30px"}}
    >
      <div className="searchBarHead DrawerBox" style={{marginBottom:"30px"}}>
        <h2 className="searchBarHeading">Action Options</h2>
        <span className="searchBarCloseIcon">
          <Tooltip title="Close">
            <IconButton onClick={() => handleCloseDrawer()}>
              <CloseIcon className="closeDrawer" />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <div style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
        {userBtn && <BtnsComponent 
        setStatusBtnClick={setStatusBtnClick}
        dataRef={dataRef} 
        openModal={openModal} 
        setOpenMoadlforNotes={setOpenMoadlforNotes} 
        fromList={true} 
        setContractPreviewModal={setContractPreviewModal} s
        setshowstageList={setshowstageList}  
        setshowtagListModal={setshowtagListModal}
        setOpenDrowerForInfoLink={setOpenDrowerForInfoLink}
        setMultiStatusModal={setMultiStatusModal}
        setStageModal={setStageModal}
        setUpgradeRoleModal={setUpgradeRoleModal}
        />}
        {opBtn && <OpBtnsComponent setStatusBtnClick={setStatusBtnClick} dataRef={dataRef} openModal={openModal} setOpenMoadlforNotes={setOpenMoadlforNotes} fromList={true} setContractPreviewModal={setContractPreviewModal}/>}
        
      
      </div>
      
    </Paper>
      
    </Drawer>
    </>
  )
}

export default BtnDower